const colors = {
	black: '#232426',
	gray: {
		100: '#F3FBFA',
		200: '#F3F1EE'
	},
	blue: {
		50: '#D2DBF1',
		100: '#AEBFE6',
		200: '#8EA5DC',
		400: '#1E4BB9',
		500: '#4747B0',
		800: '#2B215D'
	},
	teal: {
		50: '#E7F8F5',
		200: '#B5FFF2',
		300: '#54C6D3',
		400: '#71FCE7',
		600: '#00BFB5'
	},
	yellow: {
		100: '#E8E98E',
		400: '#DADB22'
	},
	red: {
		100: '#F8B9B8',
		200: '#F59695',
		300: '#F27371',
		600: '#AA004F'
	},
	purple: {
		50: '#F8F3FC',
		100: '#EDD3FF',
		200: '#C08DE2',
		300: '#9976D2'
	}
};

export default colors;
