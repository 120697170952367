import React from 'react';
import { useRouter } from 'next/router'
import { Link, } from '@chakra-ui/react';
import useAuth from 'src/lib/hooks/use-auth';

const MobileSpecialLinkSwitch = ({ href, target, label, onClose }: { href: string | null; target: string | null; label: string | null; onClose: () => void; }) => {
	const { handleLogout } = useAuth();
	const router = useRouter();

	switch (target) {
		case '_blank': {
			return (
				<a key={label} href={href ?? ''} target={target} >
					<Link fontWeight="normal" w="full" onClick={onClose} className="new-mobile-menu-item-text-style">
						<div className='new-desktop-menu-group-sub-hover-container-style'>
							<span className={router.asPath == href ? "new-mobile-menu-group-sub-active-style" : "new-mobile-menu-group-sub-inactive-style"}>&nbsp;</span>
							<span>{label}</span>
						</div>
					</Link>
				</a>
			);
		}

		case 'logout_function': {
			return (
				<button
					key={label} onClick={() => {
						onClose()
						handleLogout()
					}}>
					<Link fontWeight="normal" w="full" className="new-mobile-menu-item-text-style">
						<div className='new-desktop-menu-group-sub-hover-container-style'>
							<span className={router.asPath == href ? "new-mobile-menu-group-sub-active-style" : "new-mobile-menu-group-sub-inactive-style"}>&nbsp;</span>
							<span>{label}</span>
						</div>
					</Link>
				</button>
			);
		}

		default:
			return null;
	}
}
export default MobileSpecialLinkSwitch;
