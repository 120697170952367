import {useEffect, useRef} from 'react';

/** Passed argument will be undefined until mount. */
const useLazyState = <T>(value: T) => {
	const stateRef = useRef<T | undefined>();

	useEffect(() => {
		stateRef.current = value;
	}, [value]);

	return stateRef.current;
};

export default useLazyState;
