import {ComponentStyleConfig} from '@chakra-ui/react';

const Alert: ComponentStyleConfig = {
	baseStyle: {
		container: {
			borderRadius: '10px'
		}
	}
};

export default Alert;
