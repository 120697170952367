import React, { useMemo } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router'

import { VStack, Link, Box, useStyleConfig, ButtonProps } from '@chakra-ui/react';
import { RemoveScroll } from 'react-remove-scroll';
import useCustomSWR from 'src/lib/hooks/use-custom-swr';
import { WeeklyResponse } from 'src/lib/types';
import { LinkDef, LINKS, LINKSAccountMobileMenu, ProvidedSWRResults } from './links';
import MobileSubnavigation from './mobile-subnavigation';
import MobileSpecialLinkSwitch from "./mobile-special-Link-switch"

const LinkSwitch = ({ link, data, mobileMenuFlag, onClose }: { link: LinkDef; data: ProvidedSWRResults; mobileMenuFlag: boolean; onClose: () => void }) => {
	const buttonStyles = useStyleConfig('Button');
	const linkStyles = useStyleConfig('Link', { variant: 'navigation' });
	const router = useRouter();

	switch (link.type) {
		case 'computed': {
			const { isDisabled, href } = link.computeHref(data);
			if (isDisabled) {
				return (
					<Box
						__css={{ ...(buttonStyles as ButtonProps)._disabled, ...linkStyles }}
						pointerEvents="none"
					>
						{link.label}
					</Box>
				);
			}

			return (
				<NextLink key={link.label} passHref href={href}>
					<Link variant="navigation" onClick={onClose}>
						{link.label}
					</Link>
				</NextLink>
			);
		}

		case 'normal': {
			if (mobileMenuFlag == true) {
				return (
					<NextLink key={link.label} passHref href={link.href ?? ''}>
						<Link variant="navigation" onClick={onClose}>
							<div className='new-desktop-menu-group-sub-hover-container-style'>
								<span className={router.asPath == link.href ? "new-mobile-menu-group-sub-active-style" : "new-mobile-menu-group-sub-inactive-style"}>&nbsp;</span>
								<span>{link.label}</span>
							</div>
						</Link>
					</NextLink>
				);
			} else {
				return (
					<NextLink key={link.label} passHref href={link.href ?? ''}>
						<Link variant="navigation" onClick={onClose}>
							{link.label}
						</Link>
					</NextLink>
				);
			}
		}

		case 'special': {
			return (
				<MobileSpecialLinkSwitch
					href={link.href}
					label={link.label}
					target={link.target}
					onClose={onClose}
				/>
			);
		}

		case 'group': {
			if (mobileMenuFlag == false && link.mobileFlag == "account") {
				return null;
			} else {
				return (
					<MobileSubnavigation
						key={link.label}
						label={link.label ?? ''}
						links={link.children}
						onClose={onClose} />
				);
			}
		}

		default:
			return null;
	}
};

const MobileNavigation = ({ isOpen, onClose, mobileMenuFlag }: { isOpen: boolean; mobileMenuFlag: boolean; onClose: () => void }) => {
	const weeklyWorkouts = useCustomSWR<WeeklyResponse>('weekly');

	const data = useMemo(() => ({
		weeklyWorkouts
	}), [weeklyWorkouts]);

	// console.log("Link account: ", LINKS[5].children)
	return (
		// <RemoveScroll enabled={isOpen}>
		<VStack
			align="flex-start"
			spacing={6}
			flex="1"
			bgColor="teal.50"
			minH="calc(100vh - 70px - 2rem)"
			px={4}
			pt={4}
		>
			{mobileMenuFlag == false && LINKS.map((link, i) => <LinkSwitch key={i} link={link} data={data} onClose={onClose} mobileMenuFlag={mobileMenuFlag} />)}
			{mobileMenuFlag == true && LINKSAccountMobileMenu.map((link, i) => <LinkSwitch key={i} link={link} data={data} onClose={onClose} mobileMenuFlag={mobileMenuFlag} />)}
		</VStack>
		// </RemoveScroll>
	);
};

export default MobileNavigation;
