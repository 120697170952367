import {ComponentStyleConfig} from '@chakra-ui/react';

const Text: ComponentStyleConfig = {
	baseStyle: {
		fontSize: '16px',
		lineHeight: '22px',
		marginBottom: '8px'
	},

	variants: {
		intro: {
			fontSize: {
				base: '16px',
				lg: '20px'
			},
			lineHeight: {
				base: '22px',
				lg: '32px'
			},
			marginBottom: 0
		},
		sm: {
			fontSize: '12px',
			marginBottom: 0
		},
		timestamp: {
			fontFamily: 'Oswald',
			fontSize: 20,
			lineHeight: "22px",
			fontWeight: 400,
			letterSpacing: 1.5,
			marginBottom: 0
		},
		rest: {
			bgColor: 'blue.500',
			color: 'white',
			fontSize: '16px',
			fontWeight: '600',
			lineHeight: '18px',
			textTransform: 'uppercase',
			letterSpacing: '1.5px',
			marginBottom: 0
		}
	}
};

export default Text;
