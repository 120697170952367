import {ComponentStyleConfig} from '@chakra-ui/react';
import {Dict} from '@chakra-ui/utils';
import {transparentize} from '@chakra-ui/theme-tools';

const variantHighlighted = (props: Record<string, any>) => {
	const {colorScheme, theme} = props;

	let accentColor: string;

	switch (colorScheme) {
		case 'red':
			accentColor = transparentize('red.300', 0.75)(theme as Dict);
			break;
		case 'purple':
			accentColor = 'purple.100';
			break;
		case 'teal':
			accentColor = transparentize('teal.600', 0.5)(theme as Dict);
			break;
		case 'blue':
			accentColor = 'blue.100';
			break;
		case 'yellow':
			accentColor = 'yellow.400';
			break;
		default:
			accentColor = 'purple.100';
	}

	return {
		position: 'relative',
		width: 'min-content',
		maxW: '100%',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',

		_before: {
			content: '\'\'',
			lineHeight: '48px',
			position: 'absolute',
			height: '50%',
			left: {
				base: '-4px',
				lg: '-5px'
			},
			bottom: 0,
			right: 0,
			zIndex: -1,
			background: accentColor
		}
	};
};

const Heading: ComponentStyleConfig = {
	baseStyle: {
		zIndex: 1
	},

	sizes: {
		1: {
			fontFamily: 'Oswald',
			fontWeight: 500,
			fontSize: {
				base: '28px',
				lg: '48px'
			},
			letterSpacing: '3px',
			textTransform: 'uppercase'
		},

		'2a': {
			fontFamily: 'Montserrat',
			fontWeight: '400',
			fontSize: {
				base: '24px',
				lg: '36px'
			},
			lineHeight: {
				base: '32px',
				lg: '42px'
			},
		},

		3: {
			fontFamily: 'Oswald',
			fontWeight: 500,
			fontSize: '20px',
			lineHeight: '22px',
			textTransform: 'uppercase',
			letterSpacing: '1.5px'
		},

		'3a' : {
			fontFamily: 'Montserrat',
			fontWeight: 400,
			fontSize: '16px',
			lineHeight: '20px'
		},

		4: {
			fontFamily: 'Montserrat',
			fontWeight: 600,
			fontSize: '12px',
			letterSpacing: '3px',
			textTransform: 'uppercase'
		}
	},

	variants: {
		highlighted: variantHighlighted
	},

	defaultProps: {
		size: '1',
		colorScheme: 'purple'
	}
};

export default Heading;
