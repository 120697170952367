import React, { useMemo } from 'react';
import Router, { useRouter } from 'next/router'
import NextLink from 'next/link';
import {
	HStack,
	Link,
	Box,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useStyleConfig,
	ButtonProps,
	useDisclosure,
	IconButton,
} from '@chakra-ui/react';
import { AccountIcon } from 'src/theme/icons';

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import useCustomSWR from 'src/lib/hooks/use-custom-swr';

import { WeeklyResponse } from 'src/lib/types';
import useAuth from 'src/lib/hooks/use-auth';
import { LinkDef, LINKS, ProvidedSWRResults } from './links';

const Item = ({ label, href, isOpen, onOpen, onClose }: { label: string | null; href: string | "/"; isOpen: boolean; onOpen: () => void; onClose: () => void; }) => {
	const router = useRouter();

	if (label != "Account") {
		return (
			<MenuButton
				as={Link}
				variant="navigation"
				onMouseEnter={onOpen}
				onMouseLeave={onClose}
				onClick={() => {
					Router.push(href)
				}}
			>
				<Box
					d="flex"
					alignItems="center"
				>
					<div className={router.asPath == href ? "new-header-menu-active-container-style" : "new-header-menu-inactive-container-style"}>
						<span className={router.asPath == href ? "new-header-active-link-text-style" : ""}>{label}</span>
						{router.asPath == href && <div className='new-header-menu-active-link-style'>&nbsp;</div>}
					</div>

					{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
				</Box>
			</MenuButton>
		)
	} else {
		return (
			<MenuButton
				// as={Link}
				onMouseEnter={onOpen}
				onMouseLeave={onClose}
				onClick={() => {
					Router.push(href)
				}}
			>
				<Box className='new-header-account-icon-box-container'>
					<IconButton
						aria-label="Go to Account"
						variant="ghost"
						color="black"
						borderRadius="sm"
						icon={<AccountIcon />} />
				</Box>
			</MenuButton>
		)
	}

}

const SpecialLinkSwitch = ({ href, target, label }: { href: string | null; target: string | null; label: string | null; }) => {
	const { handleLogout } = useAuth();
	switch (target) {
		case '_blank': {
			return (
				<a key={label} href={href ?? ''} target={target}>
					<Link fontWeight="normal" w="full" className='desktop-header-menu-group-sub-a-link-under-line'>
						<div className='new-desktop-menu-group-sub-hover-container-style'>
							<span className='new-desktop-menu-group-sub-hover-style'>&nbsp;</span>
							<span>{label}</span>
						</div>
					</Link>
				</a>
			);
		}

		case 'logout_function': {
			return (
				<button key={label} onClick={handleLogout}>
					<Link fontWeight="normal" w="full" className='desktop-header-menu-group-sub-a-link-under-line'>
						<div className='new-desktop-menu-group-sub-hover-container-style'>
							<span className='new-desktop-menu-group-sub-hover-style'>&nbsp;</span>
							<span>{label}</span>
						</div>
					</Link>
				</button>
			);
		}

		default:
			return null;
	}
}

const LinkSwitch = ({ link, data }: { link: LinkDef; data: ProvidedSWRResults }) => {
	const router = useRouter();
	const buttonStyles = useStyleConfig('Button');
	const linkStyles = useStyleConfig('Link', { variant: 'navigation' });

	switch (link.type) {
		case 'computed': {
			const { isDisabled, href } = link.computeHref(data);
			// console.log("href: ", href,isDisabled, router, router.asPath);
			if (isDisabled) {
				return (
					<Box
						__css={{ ...(buttonStyles as ButtonProps)._disabled, ...linkStyles }}
						pointerEvents="none"
					>
						{link.label}
					</Box>
				);
			}

			return (
				<NextLink key={link.label} passHref href={href}>
					<Link variant="navigation">
						<div className={router.asPath == href ? "new-header-menu-active-container-style" : "new-header-menu-inactive-container-style"}>
							<span className={router.asPath == href ? "new-header-active-link-text-style" : ""}>{link.label}</span>
							{router.asPath == href && <div className='new-header-menu-active-link-style'>&nbsp;</div>}
						</div>
					</Link>
				</NextLink>
			);
		}

		case 'normal': {
			return (
				<NextLink key={link.label} passHref href={link.href}>
					<Link variant="navigation" >
						<div className={router.pathname == link.href ? "new-header-menu-active-container-style" : "new-header-menu-inactive-container-style"}>
							<span className={router.pathname == link.href ? "new-header-active-link-text-style" : ""}>{link.label}</span>
							{router.pathname == link.href && <div className='new-header-menu-active-link-style'>&nbsp;</div>}
						</div>
					</Link>
				</NextLink >
			);
		}

		case 'group': {
			const { isOpen, onOpen, onClose } = useDisclosure()
			return (
				<Menu
					key={link.label}
					isOpen={isOpen}
				>
					{link.href ? (
						<NextLink href={link.href}>
							<Item label={link.label} href={link.href} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
						</NextLink>
					) : (
						<Item label={link.label} href={"/"} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
					)}

					<MenuList
						onMouseEnter={onOpen}
						onMouseLeave={onClose}
					>
						{link.children?.map(child => (
							<MenuItem
								key={child.type === 'heading' ? child.heading : child.label}
								_hover={{ bg: 'transparent', cursor: 'unset' }}
								_focus={{ bg: 'transparent' }}
								isFocusable={false}
							>
								{
									child.type === 'heading' ? (
										<Link
											key={child.heading}
											variant="2"
										>
											{child.heading}
										</Link>
									) : (child.type === "special" ? (
										<SpecialLinkSwitch
											href={child.href}
											label={child.label}
											target={child.target}
										/>
									) : (
										<NextLink key={child.label} passHref href={child.href ?? ''}>
											<Link fontWeight="normal" w="full" className='desktop-header-menu-group-sub-a-link-under-line'>
												<div className='new-desktop-menu-group-sub-hover-container-style'>
													<span className='new-desktop-menu-group-sub-hover-style'>&nbsp;</span>
													<span>{child.label}</span>
												</div>
											</Link>
										</NextLink>
									))
								}
							</MenuItem>
						))}
					</MenuList>
				</Menu>
			);
		}

		default:
			return null;
	}
};

const DesktopNavigation = () => {
	const weeklyWorkouts = useCustomSWR<WeeklyResponse>('weekly');

	const data = useMemo(() => ({
		weeklyWorkouts
	}), [weeklyWorkouts]);

	return (
		<HStack spacing={8}>
			{LINKS.map((link, i) => <LinkSwitch key={i} link={link} data={data} />)}
		</HStack>
	);
};

export default DesktopNavigation;
