import {ComponentStyleConfig} from '@chakra-ui/react';

const variantOutline = (props: Record<string, any>) => {
	const base = {
		field: {
			borderWidth: '1px',
			borderRadius: '10px',
			bgColor: 'white'
		}
	};

	if (props.colorScheme === 'whiteAlpha') {
		return {
			field: {
				...base.field,
				color: 'white',
				bgColor: 'rgba(255, 255, 255, 0.1)',
				_placeholder: {
					color: 'white'
				}
			}
		};
	}

	return base;
};

const Input: ComponentStyleConfig = {
	variants: {
		outline: variantOutline
	}
};

export default Input;
