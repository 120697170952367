import {extendTheme} from '@chakra-ui/react';

import fonts from './foundations/fonts';
import colors from './foundations/colors';
import radii from './foundations/radius';
import shadows from './foundations/shadows';

import Accordion from './components/accordion';
import Alert from './components/alert';
import Button from './components/button';
import FormLabel from './components/form-label';
import Checkbox from './components/checkbox';
import Heading from './components/heading';
import Input from './components/input';
import Link from './components/link';
import Menu from './components/menu';
import Modal from './components/modal';
import Text from './components/text';

const theme = extendTheme({
	fonts,
	colors,
	radii,
	shadows,

	components: {
		Accordion,
		Alert,
		Button,
		FormLabel,
		Checkbox,
		Heading,
		Input,
		Link,
		Menu,
		Modal,
		Text
	}
});

export default theme;
