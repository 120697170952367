import React from 'react';
import {ChakraProvider} from '@chakra-ui/react';
import './fonts';
import theme from '.';

const ThemeProvider = ({children}: {children: React.ReactElement}) => (
	<ChakraProvider theme={theme}>
		{children}
	</ChakraProvider>
);

export default ThemeProvider;
