import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Layout from 'src/components/layout';
import useAuth from './hooks/use-auth';

const UNAUTHENTICATED_ROUTES = new Set(['/login']);

const HasJWTGuard = ({ children }: { children: React.ReactElement }) => {
	const { token, setRedirect } = useAuth();
	const router = useRouter();

	const isOnUnauthenticatedRoute = UNAUTHENTICATED_ROUTES.has(router.pathname);

	const [isReady, setIsReady] = useState(isOnUnauthenticatedRoute);

	useEffect(() => {
		if (token) {
			setIsReady(true);
		} else if (!isOnUnauthenticatedRoute) {
			setRedirect(window.location.pathname);
			void router.push('/login');
		}
	}, [token, router, isOnUnauthenticatedRoute, setRedirect]);

	if (isOnUnauthenticatedRoute) {
		return children;
	}

	if (isReady) {
		return (
			<Layout>
				{children}
			</Layout>
		);
	}

	return null;
};

export default HasJWTGuard;
