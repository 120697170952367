import {ComponentStyleConfig} from '@chakra-ui/react';

const variant2 = (props: Record<string, any>) => {
	const base = {
		_hover: {
			textDecoration: 'solid underline black 2px'
		},

		_active: {
			_before: {
				top: 0,
				left: '-2px',
				right: '-2px',
				height: '100%',
				width: 'calc(100% + 4px)',
				background: 'purple.200',
				opacity: 0.2
			}
		}
	};

	if (props.colorScheme === 'white') {
		return {
			...base,
			color: 'white',

			_hover: {
				...base._hover,
				textDecorationColor: 'white'
			}
		};
	}

	return base;
};

const Link: ComponentStyleConfig = {
	baseStyle: {
		fontWeight: 600,
		fontSize: '16px',
		position: 'relative',
		zIndex: 1,

		_active: {
			textDecoration: 'none',
			_before: {
				content: '\'\'',
				lineHeight: '16px',
				position: 'absolute',
				zIndex: -1,
				width: '100%',
				height: '1ch',
				left: '-2px',
				bottom: '3px',
				right: 0,
				background: 'purple.100'
			}
		}
	},
	variants: {
		navigation: {
			textTransform: 'uppercase',
			letterSpacing: 2,

			_hover: {
				textDecoration: 'solid underline var(--chakra-colors-purple-100) 2px'
			}
		},
		1: {
			color: 'blue.500',

			_hover: {
				textDecoration: 'solid underline var(--chakra-colors-blue-500) 2px'
			},

			_active: {
				_before: {
					top: 0,
					left: '-2px',
					right: '-2px',
					height: '100%',
					width: 'calc(100% + 4px)',
					background: 'purple.200',
					opacity: 0.2
				}
			}
		},
		2: variant2
	}
};

export default Link;
