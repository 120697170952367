import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import { useLocalStorage } from 'react-use';
import { nanoid } from 'nanoid';
import { API } from '../api';
import CONFIG from '../config';
import useLazyState from './use-lazy-state';

const ONE_WEEK_IN_SECONDS = 7 * 24 * 60 * 60;

/**
 * All-in-one hook for handling authentication.
 * Consumers just need to:
 * - Handle OAuth callbacks (with `handleAuthCallback`)
 * - Set a redirect URI if a user is logged out due to inactivity `(token === undefined)`
 *
 * See `lib/has-jwt-guard.tsx` for the other half of the auth equation.
 * */
const useAuth = () => {
	const router = useRouter();
	const [cookies, setCookie, removeCookie] = useCookies(['jwt']);
	const [redirect, setRedirect, clearRedirect] = useLocalStorage<string>('redirect-on-auth');
	const [authState, setAuthState] = useLocalStorage<string | undefined>('auth-state-param');

	// Set auth state parameter on mount rather than in init so Next.js doesn't
	// complain about a random value mismatch between the server and client generated pages
	const authStateParameter = useLazyState(authState);

	// Init state parameter
	useEffect(() => {
		if (!authState) {
			setAuthState(nanoid());
		}
	}, [authState, setAuthState]);

	const setToken = useCallback((token: string) => {
		setCookie('jwt', token, {
			path: '/',
			maxAge: ONE_WEEK_IN_SECONDS
		});
	}, [setCookie]);

	const consumeRedirect = useCallback(async () => {
		if (redirect) {
			await router.replace(redirect);
			clearRedirect();
		} else {
			await router.push('/');
		}
	}, [redirect, clearRedirect, router]);

	const handleAuthCallback = useCallback(async (code: string, _state: string) => {
		const api = new API();
		const authRes = await api.exchangeAuthCode(code);
		if(authRes?.token) {
			setToken(authRes.token);
		}		
		setAuthState(nanoid());
		await consumeRedirect();
		return authRes;
	}, [setToken, authState, setAuthState, consumeRedirect]);

	const token = cookies.jwt as string | undefined;

	const authHref = `https://${CONFIG.MEMBERFUL_SITE}.memberful.com/oauth?response_type=code&client_id=${CONFIG.MEMBERFUL_CLIENT_ID}&state=${authStateParameter ?? ''}`;

	return {
		authHref,
		token,
		handleLogout: () => {
			removeCookie('jwt', { domain: '', path: '/' });
			removeCookie('jwt');
			localStorage.clear();
			sessionStorage.clear();
			window.open("https://tlmethod.memberful.com/auth/sign_out", "_self");
		},
		handleAuthCallback,
		setRedirect,
		consumeRedirect
	};
};

export default useAuth;
