import React from 'react';
import { Box, Spinner, Image, BoxProps, Skeleton } from '@chakra-ui/react';
import { Except } from 'type-fest';

const ImageFallback = ({ ratio }: { ratio: number }) => (
	<Box
		bgColor="gray.50"
		position="relative"
		flex={1}
		width="full"
		height={0}
		pt={`calc(${ratio} * 100%)`}
	>
		<Box
			position="absolute"
			top={0}
			left={0}
			width="full"
			height="full"
			display="flex"
		>
			<Spinner m="auto" />
		</Box>
	</Box>
);

type Props = {
	isLoading?: boolean;
	src?: string;
	fallbackRatio: number;
};

type PropsCommonToImageAndBox = Except<BoxProps, 'onError'>;

export type CustomImageProps = Props & PropsCommonToImageAndBox;

const CustomImage = (props: CustomImageProps) => {
	const {
		isLoading,
		src,
		fallbackRatio,
		...boxProps
	} = props;

	return (
		<Skeleton
			isLoaded={!isLoading}
			w="full"
			h="auto"
			display="flex"
			justifyContent="center"
			position="relative"
			{...boxProps}
		>
			<Image
				src={isLoading ? undefined : src}
				fallback={<ImageFallback ratio={fallbackRatio} />}
				{...boxProps}
			// width='95%' 
			/>
		</Skeleton>
	);
};

export default CustomImage;
