import React from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { CookiesProvider } from 'react-cookie';
import { NextSeo } from 'next-seo';
import { config } from '@fortawesome/fontawesome-svg-core';
import { CustomNextPage } from 'src/lib/types';
import CustomSWRConfig from 'src/lib/custom-swr-config';
import HasJWTGuard from 'src/lib/has-jwt-guard';
import RollbarSDK from 'src/lib/rollbar-sdk';
import ThemeProvider from '../theme/provider';
import '@fortawesome/fontawesome-svg-core/styles.css';
import './style.scss'

config.autoAddCss = false;

const App = ({ Component, pageProps }: AppProps) => (
	<>
		<Head>
			<link rel="apple-touch-icon" sizes="180x180" href="/images/favicon/apple-touch-icon.png" />
			<link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png" />
			<link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png" />
			<link rel="manifest" href="/images/favicon/site.webmanifest" />
			<link rel="mask-icon" href="/images/favicon/safari-pinned-tab.svg" color="#9976d2" />
			<link rel="shortcut icon" href="/images/favicon/favicon.ico" />
			<meta name="msapplication-TileColor" content="#ffffff" />
			<meta name="msapplication-config" content="/images/favicon/browserconfig.xml" />
			<meta name="theme-color" content="#ffffff" />

			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" />
			<link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"></link>
			<link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Oswald:wght@200;300;400;500;600;700&display=swap" rel="stylesheet" />
			<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
			<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
		</Head>

		{/* <style jsx global>{`
         body {
			overflow: auto !important;
		  }
      `}</style> */}

		<NextSeo title={(Component as CustomNextPage).title ?? 'TL Method'} />
		<CookiesProvider>
			<ThemeProvider>
				<CustomSWRConfig>
					<HasJWTGuard>
						<RollbarSDK>
							<Component {...pageProps} />
						</RollbarSDK>
					</HasJWTGuard>
				</CustomSWRConfig>
			</ThemeProvider>
		</CookiesProvider>


	</>
);

export default App;
