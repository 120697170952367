import React from 'react';
import {Provider, ErrorBoundary} from '@rollbar/react';
import {Alert, AlertIcon, AlertTitle, AlertDescription} from '@chakra-ui/react';
import CONFIG from './config';

const rollbarConfig = {
	accessToken: CONFIG.ROLLBAR_ACCESS_TOKEN,
	environment: 'production'
};

const FallbackUI = () => (
	<Alert status="error">
		<AlertIcon/>
		<AlertTitle mr={2}>An error occured:</AlertTitle>
		<AlertDescription>something weird happened, but it&apos;s been reported and we&apos;re looking into it.</AlertDescription>
	</Alert>
);

const RollbarSDK = ({children}: {children: React.ReactElement | React.ReactElement[]}) => {
	return (
		<Provider config={rollbarConfig}>
			<ErrorBoundary fallbackUI={FallbackUI}>
				{children}
			</ErrorBoundary>
		</Provider>
	);
};

export default RollbarSDK;
