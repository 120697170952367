import {ComponentStyleConfig, CSSWithMultiValues} from '@chakra-ui/react';
import Heading from './heading';

const Modal: ComponentStyleConfig = {
	baseStyle: {
		dialog: {
			borderRadius: '20px',
			py: 2
		}
	},
	variants: {
		teardrop: {
			dialog: {
				borderRadius: {base: '20px', lg: '50px'},
				borderTopRightRadius: '0 !important',
				boxShadow: 'card'
			},
			header: {
				...(Heading.sizes ?? {})['3'] as CSSWithMultiValues,
				px: 8,
				pt: 8
			},
			body: {
				px: 8
			},
			footer: {
				px: 8,
				pb: 8
			}
		}
	}
};

export default Modal;
