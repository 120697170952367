import {ComponentStyleConfig} from '@chakra-ui/react';
import Heading from './heading';

const FormLabel: ComponentStyleConfig = {
	baseStyle: {
		...(Heading.sizes ?? {})['4']
	}
};

export default FormLabel;
