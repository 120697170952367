import {ComponentStyleConfig} from '@chakra-ui/react';
import {transparentize} from '@chakra-ui/theme-tools';

const Button: ComponentStyleConfig = {
	baseStyle: {
		textTransform: 'uppercase',
		fontWeight: 600,
		letterSpacing: '2px',
		fontSize: '16px',
		borderRadius: '20px',
		_focus: {
			borderColor: 'blue.400'
		}
	},
	variants: {
		primary: {
			backgroundColor: 'blue.500',
			color: 'white',

			_hover: {
				backgroundColor: 'blue.800'
			},

			_disabled: {
				backgroundColor: 'gray.200',
				color: 'black'
			}
		},

		secondary: {
			borderWidth: '2px',
			borderColor: 'blue.500',
			position: 'relative',
			bgColor: 'white',

			_hover: {
				backgroundColor: transparentize('blue.500', 0.1)
			},

			_disabled: {
				borderColor: transparentize('blue.500', 0.25),
				backgroundColor: transparentize('blue.500', 0.1)
			}
		},

		ghost: {
			borderWidth: '2px',
			borderColor: 'white',
			color: 'white',

			_hover: {
				backgroundColor: 'rgba(255, 255, 255, 0.1)'
			},

			_disabled: {
				backgroundColor: 'rgba(255, 255, 255, 0.3)'
			}
		},

		link: {
			color: 'blue.500',
			textTransform: 'none',
			letterSpacing: 0,
			fontSize: '16px',
			fontWeight: 600,
			height: 10
		}
	}
};

export default Button;
