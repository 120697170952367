import useSWR, {SWRResponse, Key, SWRConfiguration} from 'swr';
import {Fetcher} from 'swr/dist/types';

// Ideally this would be better typed as a union and then we could use
// discrimination with flow analysis, but I wasn't able to get it to work.
export type useCustomSWRReturnType<T> = SWRResponse<T, Error> & {
	isLoading: boolean;
};

// UseSWR is typed strangely, Parameters<typeof useSWR> always returns never. :(
type useCustomSWRParameters<T> = readonly [Key] | readonly [Key, Fetcher<T> | null] | readonly [Key, SWRConfiguration<T, Error> | undefined] | readonly [Key, Fetcher<T> | null, SWRConfiguration<T, Error> | undefined];

/** A simple wrapper for SWR that also returns a `isLoading` property. */
const useCustomSWR = <T>(...options: useCustomSWRParameters<T>): useCustomSWRReturnType<T> => {
	const result = useSWR<T>(...options);

	return {
		...result,
		isLoading: result.data === undefined || result.error !== undefined
	};
};

export default useCustomSWR;
