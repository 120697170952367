import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import Image from 'next/image';
import {
	Spacer,
	HStack,
	IconButton,
	Collapse,
	Box,
	useDisclosure,
	useBreakpointValue,
	Link,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { CloseIcon, AccountIcon } from 'src/theme/icons';
import LogoDark from '../../../../public/images/logo-dark.svg';
import DesktopNavigation from './desktop-navigation';
import MobileNavigation from './mobile-navigation';

const NavigationBar = () => {
	const { isOpen, onToggle, onClose } = useDisclosure();
	const currentBreakpoint = useBreakpointValue({ base: 'base', md: 'md', lg: 'lg' });
	const [mobileMenuFlag, setMobileMenuFlag] = useState(false);

	useEffect(() => {
		if (currentBreakpoint === 'md') {
			onClose();
		}
	}, [onClose, currentBreakpoint]);

	return (
		<Box position="relative" w="full" zIndex={1000}>
			<HStack
				w="full"
				bgColor="white"
				py={4}
				px={{ base: 4, md: 16 }}
				boxShadow="0 0 10px 0 var(--chakra-colors-teal-50)"
				className='new-header-container-padding-style'
			>
				<NextLink passHref href="/">
					<Box
						as="a"
						boxSize={{ base: '70px', lg: '100px' }}
						pos="relative"
						onClick={onClose}
					>
						<Image src={LogoDark} layout="fill" />
					</Box>
				</NextLink>

				<Spacer />
				{/* <Box d={{ base: 'block', md: 'none' }}>
					<NextLink key={"Account"} passHref href={"/account/notes"}>
						<Link variant="navigation" onClick={onClose}>
							<IconButton
								aria-label="Go to Account"
								variant="ghost"
								color="black"
								borderRadius="sm"
								icon={<AccountIcon />}
							/>
						</Link>
					</NextLink>
				</Box> */}

				<Box d={{ base: 'block', md: 'none' }}>
					<IconButton
						aria-label="Go to Account"
						variant="ghost"
						color="black"
						borderRadius="sm"
						icon={(isOpen && mobileMenuFlag == true) ? <CloseIcon boxSize="1.5em" /> : <AccountIcon />}
						onClick={() => {
							onToggle();
							setMobileMenuFlag(true)
						}} />
				</Box>

				<Box d={{ base: 'block', md: 'none' }}>
					<IconButton
						aria-label="Open navigation"
						variant="ghost"
						color="black"
						borderRadius="sm"
						icon={(isOpen && mobileMenuFlag == false) ? <CloseIcon boxSize="1.5em" /> : <HamburgerIcon boxSize="1.5em" />}
						onClick={() => {
							onToggle();
							setMobileMenuFlag(false)
						}} />
				</Box>

				<Box d={{ base: 'none', md: 'block' }}>
					<DesktopNavigation />
				</Box>
			</HStack>

			<Collapse
				in={isOpen}
				style={{
					width: '100%',
					position: 'absolute',
					top: 'calc(70px + 2rem)',
					left: 0
				}}
			>
				<MobileNavigation
					mobileMenuFlag={mobileMenuFlag}
					isOpen={isOpen}
					onClose={onClose}
				/>
			</Collapse>
		</Box>
	);
};

export default NavigationBar;
