import React from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router'

import {
	VStack,
	Link,
	Collapse,
	useDisclosure
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { GroupLink } from './links';
import MobileSpecialLinkSwitch from "./mobile-special-Link-switch"

type SubnavigationProps = {
	label: string;
	links: GroupLink['children'];
	onClose: () => void;
};

const MobileSubnavigation = ({ label, links, onClose }: SubnavigationProps) => {
	const { isOpen, onToggle } = useDisclosure();
	const router = useRouter();

	return (
		<VStack align="flex-start">
			<Link
				variant="navigation"
				alignItems="center"
				d="flex"
				onClick={onToggle}
			>
				{label}

				{isOpen ? <ChevronUpIcon boxSize="1.2em" /> : <ChevronDownIcon boxSize="1.2em" />}
			</Link>

			<Collapse in={isOpen}>
				<VStack align="flex-start" pl={4} spacing={3}>
					{
						links.map(link => link.type === 'heading' ? (
							<Link
								key={link.heading}
								variant="2"
							>
								{link.heading}
							</Link>
						) : (link.type === "special" ? (
							<MobileSpecialLinkSwitch
								href={link.href}
								label={link.label}
								target={link.target}
								onClose={onClose}
							/>
						) : (
							<NextLink key={link.label} passHref href={link.href ?? ''}>
								<Link variant="2" fontWeight="normal" onClick={onClose}>
									<div className='new-desktop-menu-group-sub-hover-container-style'>
										<span className={router.asPath == link.href ? "new-mobile-menu-group-sub-active-style" : "new-mobile-menu-group-sub-inactive-style"}>&nbsp;</span>
										<span>{link.label}</span>
									</div>
								</Link>
							</NextLink>
						)))
					}
				</VStack>
			</Collapse>
		</VStack>
	);
};

export default MobileSubnavigation;
