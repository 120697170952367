import {ComponentStyleConfig} from '@chakra-ui/react';

const Checkbox: ComponentStyleConfig = {
	baseStyle: {
		_focus: {
			borderColor: 'blue.400'
		}
	}
};

export default Checkbox;
