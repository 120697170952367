import {ComponentStyleConfig} from '@chakra-ui/react';

const Menu: ComponentStyleConfig = {
	baseStyle: {
		list: {
			borderRadius: '20px',
			borderTopLeftRadius: 0,
			boxShadow: '0 3px 10px 0 rgba(35,36,38,0.15)',
			border: 'none',
			overflow: 'hidden'
		}
	}
};

export default Menu;
