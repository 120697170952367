import { useCustomSWRReturnType } from 'src/lib/hooks/use-custom-swr';
import parseDateStr from 'src/lib/parse-date-str';
import { WeeklyResponse } from 'src/lib/types';

export interface ProvidedSWRResults {
	weeklyWorkouts: useCustomSWRReturnType<WeeklyResponse>;
}

export type ComputedLink = {
	computeHref: (data: ProvidedSWRResults) => { href: string; isDisabled: boolean };
	label: string;
	type: 'computed';
};

export type NormalLink = {
	href: string;
	label: string;
	type: 'normal';
};

export type SpecialLink = {
	href: string;
	label: string;
	target: string;
	type: 'special';
};

export type GroupLink = {
	children: Array<NormalLink | SpecialLink | { heading: string; type: 'heading' }>;
	href: string | null;
	label: string;
	type: 'group';
	mobileFlag: string;
};

export type LinkDef = ComputedLink | GroupLink | NormalLink | SpecialLink;

export const LINKS: LinkDef[] = [
	{
		computeHref: ({ weeklyWorkouts }) => {
			if (weeklyWorkouts.data) {
				const today = weeklyWorkouts.data.workouts?.find(workout => parseDateStr(workout.date).getDay() === new Date().getDay());

				if (today) {
					return {
						isDisabled: false,
						href: `/workouts/${today.id}`,
					};
				}
			}

			return {
				isDisabled: true,
				href: '/'
			};
		},
		label: 'Today\'s Workout',
		type: 'computed'
	},
	{
		href: '/workouts',
		label: 'All Workouts',
		type: 'normal'
	},
	{
		href: '/favorites',
		label: 'Favorites',
		type: 'normal'
	},
	{
		href: '/education?category=0',
		label: 'Education',
		type: 'group',
		mobileFlag: 'general',
		children: [
			{
				href: "/education?category=0",
				label: "Exercise Breakdowns",
				type: "normal",
			},
			{
				href: "/education?category=1",
				label: "Fundamentals",
				type: "normal",
			},
			{
				href: "/education?category=2",
				label: "Nutrition",
				type: "normal",
			},
			{
				href: "/education?category=3",
				label: "Routine",
				type: "normal",
			}
		]
	},
	{
		href: '/recovery?category=0',
		label: 'RECOVERY',
		type: 'group',
		mobileFlag: 'general',
		children: [
			{
				href: "/recovery?category=0",
				label: "Cool Downs",
				type: "normal",
			},
			{
				href: "/recovery?category=1",
				label: "Mindset",
				type: "normal",
			},
			{
				href: "/recovery?category=2",
				label: "Mobility",
				type: "normal",
			},
			{
				href: "/recovery?category=3",
				label: "Recovery",
				type: "normal",
			},
			{
				href: "/recovery?category=4",
				label: "Warm-Ups",
				type: "normal",
			},
			{
				href: "/recovery?category=5",
				label: "Yoga",
				type: "normal",
			},

		]
	},
	{
		href: '/account/notes',
		label: 'Account',
		type: 'group',
		mobileFlag: 'account',
		children: [
			{
				href: "https://tlmethod.memberful.com/account",
				target: "_blank",
				label: "Account Details",
				type: "special",
			},
			{
				href: "/account/contact",
				label: "Contact",
				type: "normal",
			},
			{
				href: "/account/notes",
				label: "Exercise Notes",
				type: "normal",
			},
			{
				href: "/account/faq",
				label: "FAQ",
				type: "normal",
			},
			{
				href: "/account/perks",
				label: "Perks",
				type: "normal",
			},
			{
				href: "none",
				target: "logout_function",
				label: "Logout",
				type: "special",
			}
		]
	}
];

export const LINKSAccountMobileMenu: LinkDef[] = [
	{
		href: "https://tlmethod.memberful.com/account",
		target: "_blank",
		label: "Account Details",
		type: "special",
	},
	{
		href: "/account/contact",
		label: "Contact",
		type: "normal",
	},
	{
		href: "/account/notes",
		label: "Exercise Notes",
		type: "normal",
	},
	{
		href: "/account/faq",
		label: "FAQ",
		type: "normal",
	},
	{
		href: "/account/perks",
		label: "Perks",
		type: "normal",
	},
	{
		href: "none",
		target: "logout_function",
		label: "Logout",
		type: "special",
	}
];
