import React, { useMemo } from 'react';
import { Box, Divider, Heading, BoxProps } from '@chakra-ui/react';

const Footer = (props: BoxProps) => {
	const year = useMemo(() => new Date().getFullYear().toString(), []);

	return (
		<Box bgColor="white" {...props} marginTop={30} paddingTop={0} className="new-footer-container-padding-style">
			<Divider borderBottomWidth={2} borderColor="teal.300" />

			<Heading size="4" py={4} pl={2}>
				© {year} TARA LAFERRARA
			</Heading>
		</Box>
	);
};

export default Footer;
