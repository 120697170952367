import {ComponentStyleConfig} from '@chakra-ui/react';

const Accordion: ComponentStyleConfig = {
	baseStyle: {
		container: {
			borderTopWidth: '2px',
			borderColor: 'blue.500',
			_first: {
				borderTopWidth: 0
			},
			_last: {
				borderBottomWidth: '2px'
			}
		},
		button: {
			py: 4
		}
	},
	variants: {
		filters: {
			button: {
				pt: 8,
				pb: 2
			}
		},
		bubbles: {
			panel: {
				bgColor: 'purple.100',
				borderRadius: '20px',
				fontWeight: 'semibold',
				my: 6,
				p: 4,
				position: 'relative',

				'& > p': {
					mb: 0
				},

				_after: {
					content: '\'\'',
					position: 'absolute',
					top: 'calc(-1.1rem + 2px)',
					left: '22px',
					width: 0,
					height: 0,
					borderBottom: '1rem solid transparent',
					borderBottomColor: 'purple.100',
					borderRight: '1.1rem solid transparent'
				}
			}
		}
	}
};

export default Accordion;
