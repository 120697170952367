import React, { useMemo, useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import { SWRConfig } from 'swr';
import useAuth from './hooks/use-auth';
import ky, { HTTPError } from './ky';

const TOAST_ID = 'api-error-toast';

const CustomSWRConfig = ({ children }: { children: React.ReactElement }) => {
	const { token, handleLogout } = useAuth();
	const toast = useToast();

	const kyInstance = useMemo(() => token ? ky.extend({
		headers: {
			authorization: `Bearer ${token}`
		}
	}) : ky, [token]);

	const fetcher = useCallback(async (url: string) => {
		try {
			const data = await kyInstance.get(url).json();
			toast.close(TOAST_ID);
			return data;
		} catch (error: unknown) {
			let errorMessage = 'We\'re having issues loading data right now. Please try again later.';

			if ((error as HTTPError).response.status === 404) {
				errorMessage = 'Data could not be found. Please try again later.';
			} else if ((error as HTTPError).response.status === 403) {
				console.log("403_error: ", (error as HTTPError).response);
				// Logout / re-auth
				handleLogout();
				toast.closeAll();
				return;
			}

			if (!toast.isActive(TOAST_ID)) {
				toast({
					id: TOAST_ID,
					title: 'Error',
					description: errorMessage,
					status: 'error',
					duration: null
				});
			}

			throw error;
		}
	}, [kyInstance, toast, handleLogout]);

	return (
		<SWRConfig value={{ fetcher }}>
			{children}
		</SWRConfig>
	);
};

export default CustomSWRConfig;
