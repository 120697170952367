import React, { useEffect, useMemo, useState } from 'react';
import {
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	ModalCloseButton,
	Heading,
	useDisclosure,
	VStack,
	HStack,
	Progress,
	Button,
	IconButton,
	Box,
	Spacer
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useLocalStorage, usePrevious } from 'react-use';
import useCustomSWR from 'src/lib/hooks/use-custom-swr';
import { TutorialResponse } from 'src/lib/types';
import bowser from 'bowser';
import CustomImage from '../common/custom-image';

const TutorialModal = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { data: tutorial } = useCustomSWR<TutorialResponse>('images/tutorial');
	const [currentIndex, setCurrentIndex] = useState(0);
	const [hasShown, setHasShown] = useLocalStorage('has-show-tutorial', false);

	const isDesktop = useMemo(() => {
		const browser = bowser.getParser(window.navigator.userAgent);

		return browser.getPlatform().type === 'desktop';
	}, []);

	const imageUrls = useMemo(() => {
		if (!tutorial) {
			return [];
		}

		if (isDesktop) {
			return tutorial.desktop;
		}

		return tutorial.mobile;
	}, [tutorial, isDesktop]);


	const imageRatio = useMemo(() => {
		if (isDesktop) {
			return 0.53;
		}

		return 1.77;
	}, [isDesktop]);

	useEffect(() => {
		if (imageUrls.length > 0 && !hasShown) {
			onOpen();
		}
	}, [imageUrls, onOpen, hasShown]);

	const wasOpen = usePrevious(isOpen);
	useEffect(() => {
		if (wasOpen && !isOpen) {
			setHasShown(true);
		}
	}, [wasOpen, isOpen, setHasShown]);

	return (
		<Modal
			isOpen={isOpen}
			closeOnOverlayClick={false}
			size="6xl"
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Heading size="3">
						Tutorial
					</Heading>
				</ModalHeader>
				<ModalCloseButton />

				<ModalBody>
					<VStack spacing={5}>
						<CustomImage
							src={imageUrls[currentIndex]}
							maxH={`calc(1vw * ${isDesktop? 53 : 177})`}
							w="100%"
							fallbackRatio={imageRatio}
							onClick={() => {
								if (currentIndex < imageUrls.length - 1) {
									setCurrentIndex(i => i + 1);
								}
							}} />

						{
							// Preload next image
							currentIndex < imageUrls.length - 1 && (
								<CustomImage
									maxH={`calc(1vw * ${isDesktop? 53 : 177})`}
									pos="absolute"
									top={0}
									zIndex={-1}
									opacity={0}
									src={imageUrls[currentIndex + 1]}
									fallbackRatio={imageRatio} />
							)
						}

						<HStack w="full">
							<Box flex={1}>
								{
									currentIndex !== 0 && (
										<IconButton
											colorScheme="blue"
											icon={<ChevronLeftIcon />}
											aria-label="Previous image"
											onClick={() => {
												setCurrentIndex(i => i - 1);
											}} />
									)
								}
							</Box>

							<Progress flex={4} value={((currentIndex + 1) / imageUrls.length) * 100} rounded="md" />

							<Box flex={1} display="flex">
								<Spacer />
								{
									currentIndex === imageUrls.length - 1 ? (
										<Button variant="primary" onClick={onClose}>Close</Button>
									) : (
										<IconButton
											colorScheme="blue"
											icon={<ChevronRightIcon />}
											aria-label="Next image"
											onClick={() => {
												setCurrentIndex(i => i + 1);
											}} />
									)
								}
							</Box>
						</HStack>
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default TutorialModal;
