import React from 'react';
import { Box } from '@chakra-ui/react';
import NavigationBar from './navigation-bar';
import Footer from './footer';
import TutorialModal from './tutorial-modal';

const Layout = ({ children }: { children: React.ReactElement }) => {
	return (
		<Box minH="calc(100vh)" d="flex" flexDir="column">
			<NavigationBar />

			<Box flex="1">
				{children}
			</Box>

			<Footer mt={8} />

			<TutorialModal />
		</Box>
	);
};

export default Layout;
