import ky, { HTTPError } from 'ky';
import { Except } from 'type-fest';
import CONFIG from './config';
import { AuthCodeExchangeResponse, ExerciseResponse, FavoritesBoard, FavoritesBoardResponse, NoteResponse } from './types';

export class API {
	private ky: typeof ky;

	constructor({ onError }: { onError?: (error: HTTPError) => void } = {}) {
		this.ky = ky.extend({
			prefixUrl: CONFIG.API_ENDPOINT,
			hooks: {
				afterResponse: [
					(request, options, response) => {
						if (!response.ok && onError) {
							onError(new HTTPError(response, request, options));
						}
					}
				]
			}
		});
	}

	setToken(jwt: string) {
		this.ky = this.ky.extend({
			headers: {
				authorization: `Bearer ${jwt}`
			}
		});
	}

	async exchangeAuthCode(code: string) {
		return this.ky.post('users/login', {
			json: {
				authCode: code
			}
		}).json<AuthCodeExchangeResponse>().catch(err => {
			let responseObject = {
				status: "error",
				token: null,
				userIsActive: false,
			}
			return responseObject;
		});
	}

	async createNote({ exerciseId, note }: { exerciseId: ExerciseResponse['id']; note: string }) {
		return this.ky.post(`exercises/${exerciseId}/note`, {
			json: {
				data: note
			}
		}).json<NoteResponse>();
	}

	async updateNote({ noteId, note }: { noteId: NoteResponse['id']; note: string }) {
		return this.ky.put(`notes/${noteId}`, {
			json: {
				data: note
			}
		}).json<NoteResponse>();
	}

	async deleteNote(noteId: NoteResponse['id']) {
		return this.ky.delete(`notes/${noteId}`).json();
	}

	async createFavoriteBoard(board: Except<FavoritesBoard, 'id'>) {
		return this.ky.post('users/favorites', {
			json: board
		}).json<FavoritesBoardResponse>();
	}

	async updateFavoritesBoard(id: FavoritesBoard['id'], board: Except<FavoritesBoard, 'id'>) {
		return this.ky.put(`users/favorites/${id}`, {
			json: board
		});
	}

	async deleteFavoritesBoard(boardId: FavoritesBoard['id']) {
		return this.ky.delete(`users/favorites/${boardId}`);
	}

	async sendContactMessage(message: string) {
		return this.ky.post('contact', {
			json: {
				body: message
			}
		});
	}
}
